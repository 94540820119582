import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import logo from "../../assets/images/logo.jpeg"

const NavbarTwo = () => {



    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })


    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area navbar-style-two">
                <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" />
                            </Link>



                            <div id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">

                                    </div>
                                </div>
                            </div>
                            <a
                                href="https://api.whatsapp.com/send?text=Hi&phone=+919005165551" target="_blank"
                            >
                                <i className="flaticon-right"></i> Chat @ 9005165551 <span></span>
                            </a>
                        </nav>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default NavbarTwo
