import React from 'react'
import {Link} from 'gatsby'
import decorImg from '../../assets/images/Decor.png'
import photographyImg from '../../assets/images/Photography.png'
import invitesImg from '../../assets/images/Invites.png'
import thImg from '../../assets/images/TH.png'
import venueImg from '../../assets/images/Venue.png'
import videographyImg from '../../assets/images/Videography.png'
import cateringImg from '../../assets/images/Catering.png'
import makeupImg from '../../assets/images/Makeup.png'
import entertainmentImg from '../../assets/images/Entertainment.png'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={decorImg} alt="icon" />
                            </div>
                            <h3>
                                
                                Decor
                                
                            </h3>
                            <p>Experts in indoor and outdoor decor. Creating marvel and breathtaking landscapes.</p>

                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={photographyImg} alt="icon" />
                            </div>
   
                            <h3>
                                
                                Photography
                                
                            </h3>

                            <p>Skilled photographers mastering in stills to create impressionable moments and portfolios for lifetime.</p>
                            
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={videographyImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Videography
                                
                            </h3>

                            <p>Professional video designers for shooting movie like in-trend pre-wedding/wedding events.</p>
                            
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={invitesImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Invites
                                
                            </h3>

                            <p>Customized and creative invite crafting to ensure an impacting first impression.</p>
                           
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={cateringImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Catering
                                
                            </h3>

                            <p>Complete landscape of food spread, eye for taste and savory that will leave the guests with a delight and praise.</p>
                            
                            
                        </div>
                    </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={makeupImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Makeup & Hairstyle
                                
                            </h3>

                            <p> Team of makeup artists and stylists for extensive experience with bride, bridegroom and guests for the looks and styles in vogue and latest trends.</p>
                            
                            
                        </div>
                    </div>


                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={entertainmentImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Entertainment
                                
                            </h3>

                            <p>Magicians in creating the mood with troupes, bands, orchestra, DJ, dhol, folk artists, live music, and more.</p>
                            
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={venueImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Venue
                                
                            </h3>

                            <p>Our vast experience and collaborations offers a wide range of venue choices.</p>
                            
                            
                        </div>
                    </div>

                    


                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={thImg} alt="icon" />
                            </div>

                            <h3>
                                
                                Travel & Hospitality
                                
                            </h3>

                            <p>End to end logistics, transit stays and accomodation with world class service and hospitality with security.</p>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne
